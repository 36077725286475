import React, {useCallback, useEffect, useState} from 'react';
import Calendar from "react-calendar";
import Slots from "../slots/Slots";
import Input from "./input";
import BookingApp from "../../../API/bookingApp";
import {useForm} from "react-hook-form";
import {Loader} from "../Loader/Loader";
import {ModalMessage} from "../ModalMessage/ModalMessage";
import {AgreementComponent} from "../../AgreementComponent";

const BookingForm = (props) => {

    const {
        setModalActive,
        selectedProduct,
        duration,
    } = props;

    const [dateValue, setDateValue] = useState(new Date());
    const [slots, setSlots] = useState([]);
    const [timeValue, setTimeValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [requestSuccess, setRequestSuccess] = useState(false)
    const [requestError, setRequestError] = useState('')
    const [disabledDays, setDisabledDays] = useState([]);
    const [isAgree, setIsAgree] = useState(false);
    const today = new Date();

    const {
        register,
        watch,
        setValue,
        handleSubmit,
        reset,
        formState: {errors}
    } = useForm({
        defaultValues: {
            username: '',
            telegram: '',
            question: '',
            email: '',
            linkedin_url: '',
        },
        mode: "onBlur",
    });
    const {
        username,
        telegram,
        question,
        email,
        linkedin_url,
    } = watch();

    function onClickTimeSlot(btnText) {
        setTimeValue(btnText);
    }

    const fetchDisabledDays = () => {
        BookingApp.getDisabledDays()
            .then(resp => {
                setDisabledDays(resp);
            })
            .catch(e => {
                console.log(e);
            })
    }

    useEffect(() => {
        fetchDisabledDays();
    }, [])

    useEffect(() => {
        register('username', {
            required: {
                value: true,
                message: 'Поле обязательно к заполнению',
            },
        });
        register('telegram', {
            required: {
                value: true,
                message: 'Поле обязательно к заполнению',
            },
        });
        register('question', {
            required: {
                value: true,
                message: 'Поле обязательно к заполнению',
            },
        });
        register('email', {
            required: {
                value: false,
                message: 'Веден невалидный email',
            },
            pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/i
        });
        register('linkedin_url');
    }, [
        register,
        timeValue,
        dateValue,
        selectedProduct,
        setIsLoading,
        errors,
        requestError,
        requestSuccess,
    ])

    const handleChange = (e, name) => {
        setValue(name, e.target.value);
    }

    async function createConsultation(data) {
        await BookingApp.sendRequest(data);
    }

    const onClickDay = (dateValue) => {
        const data = {'consultation_date': dateValue.toLocaleDateString('en-ca')};
        BookingApp.getSlots(data)
            .then((resp) => {
                setTimeValue('');
                setSlots(resp);
            })
            .catch((error) => {
                console.log(error.message);
            })
    }

    const onCloseModal = () => {
        reset();
        setDateValue(new Date());
        setTimeValue('')
        setModalActive(false);
        setRequestSuccess(false);
        setRequestError('')
    }

    const reduceLabel = (data) => {
        const {label} = data
        if (label) {
            const arr = label.split(" ")
            if (arr[0].length > 3) {
                arr[0] = `${arr[0].substring(0, 3)}.`
            }
            return arr.join(" ")
        }
    }

    const disableDay = (date) => {
        return date.getDay() === 6
            || date.getDay() === 0
            || disabledDays.includes(date.toLocaleDateString('en-ca'))
    }

    const onChangeAgree = useCallback(() => {
        setIsAgree(!isAgree)

    }, [isAgree])

    const onSubmit = (data) => {
        console.log(data)
        if (timeValue && dateValue && !(dateValue.getDay() === 6 || dateValue.getDay() === 0)) {
            setIsLoading(true)
            createConsultation({
                ...{
                    consultation_date: dateValue.toLocaleDateString('en-ca'),
                    consultation_time: timeValue + ':00',
                    product: selectedProduct,
                }, ...data
            })
                .then(() => {
                    setIsLoading(false);
                    setRequestSuccess(true);
                    setTimeout(() => {
                        onCloseModal()
                    }, [10000]);
                })
                .catch((e) => {
                    setIsLoading(false);
                    const message = (e instanceof Error) ? e?.message : "Unexpected error."
                    setRequestError(message);
                    setTimeout(() => {
                        onCloseModal()
                    }, [10000]);
                })
        }
    }

    if (requestSuccess) {
        return (
            <ModalMessage
                callback={onCloseModal}
            >
                Запрос принят.
                Я скоро с Вами свяжусь.
            </ModalMessage>
        )
    }

    if (requestError) {
        return (
            <ModalMessage
                callback={onCloseModal}
            >
                {requestError}
            </ModalMessage>
        )
    }

    return (
        isLoading
            ?
            <Loader/>
            :
            <form
                className="booking__form"
                onSubmit={handleSubmit(onSubmit)}
            >
                <Calendar
                    onChange={setDateValue}
                    value={dateValue}
                    tileDisabled={({date}) => disableDay(date)}
                    navigationLabel={reduceLabel}
                    minDate={new Date()}
                    maxDate={new Date(today.setDate(today.getDate() + duration))}
                    onClickDay={(dateValue) => onClickDay(dateValue)}
                />
                {
                    slots.length
                        ?
                        (<>
                            {

                                <div
                                    className={'booking__form__title micro-title'}>
                                    {
                                        !timeValue &&
                                        <p>{!timeValue && "Выберете время консультации"}</p>
                                    }

                                </div>
                            }
                            {
                                <>
                                    <p>*время московское</p>
                                    <Slots
                                        className={"time-slot"}
                                        items={slots}
                                        timeValue={timeValue}
                                        onClick={e => onClickTimeSlot(e)}

                                    />

                                </>
                            }
                        </>)
                        : null
                }
                <div
                    className={"booking__feedback-form feedback-form"}
                >
                    {
                        errors?.username && (
                            <div className={'booking__form__error-title'}>
                                {errors?.username.message}
                            </div>
                        )
                    }
                    <Input
                        className={"booking__feedback-input feedback-input"}
                        type={"text"}
                        name={"username"}
                        placeholder={"Введите Ваше имя*"}
                        value={username}
                        onChange={e => handleChange(e, 'username')}
                    />
                    {
                        errors?.telegram && (
                            <div className={'booking__form__error-title'}>
                                {errors.telegram.message}
                            </div>
                        )
                    }
                    <Input
                        className={"booking__feedback-input feedback-input"}
                        type={"text"}
                        name={"telegram"}
                        placeholder={"Введите Ваш телеграм*"}
                        value={telegram}
                        onChange={e => handleChange(e, 'telegram')}
                    />
                    {
                        errors?.question && (
                            <div className={'booking__form__error-title'}>
                                {errors?.question.message}
                            </div>
                        )
                    }
                    <Input
                        className={"booking__feedback-input feedback-input"}
                        type={"text"}
                        name={"question"}
                        placeholder={"Введите Ваш запрос*"}
                        value={question}
                        onChange={e => handleChange(e, 'question')}
                    />
                    {
                        errors?.email && (
                            <div className={'booking__form__error-title'}>
                                {'Веден невалидный Email'}
                            </div>
                        )
                    }
                    <Input
                        className={"booking__feedback-input feedback-input"}
                        type={"text"}
                        name={"email"}
                        placeholder={"Email"}
                        value={email}
                        onChange={e => handleChange(e, 'email')}
                    />
                    <Input
                        className={"booking__feedback-input feedback-input"}
                        type={"text"}
                        name={"linkedinUrl"}
                        placeholder={"LinkedIn"}
                        value={linkedin_url}
                        onChange={e => handleChange(e, 'linkedin_url')}
                    />
                </div>
                <AgreementComponent
                    onChange={onChangeAgree}
                    checked={isAgree}
                    className={'agreement'}
                    labelClassName={''}
                    inputClassName={'agreement__input'}
                />
                <input
                    type={'submit'}
                    className={"booking__btn btn"}
                    disabled={!isAgree}
                />
            </form>
    );
};

export default BookingForm;