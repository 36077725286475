import React from 'react';
import TimeSlot from "./timeSlot";

const Slots = (props) => {

    const {
        className,
        timeValue,
        items,
        onClick
    } = props;

    return (
        <div className={className}>
            {items.map((item) =>
                <TimeSlot
                    className={
                        timeValue === item.consultation_time.slice(0, 5)
                            ? "time-slot__btn active"
                            : "time-slot__btn"
                    }
                    key={item.id}
                    // onClick={e => onClick(e)}
                    onClick={onClick}
                    disabled={item.is_booked}

                >
                    {item.consultation_time.slice(0, 5)}
                </TimeSlot>)}
        </div>
    );
};

export default Slots;