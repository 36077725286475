import React, { useEffect, useState } from 'react';
import Card from "./UI/Card";
import Modal from "./UI/Modal/Modal";
import BookingApp from "../API/bookingApp";
import { ProductCard } from "./UI/ProductCard";
import { useScrollLoc } from "../hooks/useScrollLoc";
import BookingForm from "./UI/forms/BookingForm";

const BookingContent = () => {

    const [bookingItems, setBookingItems] = useState([]);
    const [modalActive, setModalActive] = useState(false);
    const [userChoice, setUserChoice] = useState({});
    const [calendarDuration, setCalendarDuration] = useState(13);
    const { lockScroll, unlockScroll } = useScrollLoc();


    async function fetchProducts() {
        await BookingApp.getProducts()
            .then(products => {
                setBookingItems(products);
            })
            .catch(e => {
                console.log(e);
            });
    }

    async function fetchSettings() {
        await BookingApp.getSettings()
            .then(resp => {
                setCalendarDuration(resp.calendar_duration)
            })
            .catch(e => {
                console.log(e)
            })
    }

    useEffect(() => {
        fetchProducts();
        fetchSettings();
    }, [])


    useEffect(() => {
        if (modalActive) {
            lockScroll();
        }

        if (!modalActive) {
            unlockScroll();
            setUserChoice({});
        }
    }, [lockScroll, modalActive, unlockScroll])

    const handleChoice = (product) => {
        if (product) {
            setUserChoice(product)
        }

        setModalActive(true);
    }

    return (
        <div className="booking__container _container">
            <Card
                className={"booking__content"}
                titleClassName={"booking__title small-title"}
                contentClassName={"booking__items"}
                titleText={
                    <p>
                        Забронировать консультацию
                    </p>
                }
                content={bookingItems?.map(item =>
                    <Card
                        className={"booking__card"}
                        titleClassName={"booking__item-title micro-title"}
                        contentClassName={"booking__item-content text"}
                        titleText={item.title}
                        content={
                            <ProductCard
                                item={item}
                                handleChoice={handleChoice}
                            />
                        }
                        key={item.id}
                    />
                )}
            />
            <Modal active={modalActive} setActive={setModalActive}>
                <BookingForm
                    selectedProduct={userChoice}
                    modalActive={modalActive}
                    setModalActive={setModalActive}
                    duration={calendarDuration}
                />
            </Modal>
        </div>
    );
};

export default BookingContent;