import axios from "axios";
import {API_ORIGIN} from "./config";

export default class BookingApp {
    static async getProducts() {
        const response = await axios.get(
            `${API_ORIGIN}/api/v1/products/`
        );
        return response.data;
    }

    static async getSettings() {
        const response = await axios.get(
            `${API_ORIGIN}/api/v1/booking_settings/1`
        );
        return response.data;
    }

    static async sendRequest(data) {
        return await axios.post(
            `${API_ORIGIN}/api/v1/orders/`,
            data,
        );
    }

    static async getSlots(data) {
        const response =  await axios.get(
            `${API_ORIGIN}/api/v1/orders/`,
            {
                params: data
            },
        );
        return response.data;
    }

    static async getDisabledDays() {
            const response =  await axios.get(
                `${API_ORIGIN}/api/v1/disabled_days/`,
            );
            return response.data;
        }

}