import './Loader.css'

export const Loader = () => {
    return (
        <div className="loadingio-spinner-spinner-pehlc146h7m">
            <div className="ldio-nl41gg5mtb">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}