import React from 'react';

const Avatar = (props) => {
    return (
        <div className={props.className}>
            <img src={props.src} alt=""/>
        </div>
    );
};

export default Avatar;