import React, {memo} from "react";
import {DocumentParagraphItem} from "./DocumentParagraphItem";


export const DocumentParagraph = memo(({paragraph}) => {

    return (
        <div className={'personalDataPolicy__paragraph'}>
            <h2
                className={'personalDataPolicy__paragraph__title micro-title'}
            >
                {`${paragraph.number}. ${paragraph.title}`}
            </h2>
            <div className={''}>
                {
                    paragraph.items && paragraph.items.map(item => (
                        <DocumentParagraphItem
                            key={item.id}
                            paragraph={paragraph}
                            item={item}
                        />
                    ))
                }
            </div>

        </div>
    )
})