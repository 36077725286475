import React, {useEffect, useState} from 'react';
import Title from "./title";
import MainApp from "../API/mainApp";
import {Link} from "react-router-dom";

const Footer = () => {
    const [helloContent, setHelloContent] = useState({});
    const [currentYear, setCurrentYear] = useState();

    async function fetchHelloContent() {
        const content = await MainApp.getHelloContent();
        setHelloContent(content);
    }

    useEffect(() => {
        setCurrentYear(new Date().getFullYear())
        fetchHelloContent()
    }, [])

    return (
        <footer className="footer">
            <div className="footer__content _container">
                <Title className={"footer__title small-title"}>
                    <p>
                        Остались <span className={"title-italic"}>вопросы</span>?
                    </p>
                </Title>
                <div className="footer__text text">
                    Свяжитесь со мной через мессенджер или социальные сети
                </div>
                <div className="footer__social social">
                    <a
                        href={helloContent.telegram}
                        className="social__item _icon-telegram"
                        target={'_blank'}
                    ></a>
                    <a
                        href={helloContent.linked_in}
                        className="social__item _icon-linkedin"
                        target={'_blank'}
                    ></a>
                    <a
                        href={helloContent.instagram}
                        className="social__item _icon-instagram"
                        target={'_blank'}
                    ></a>
                </div>
                <div className={"footer__copy"}>
                    <Link
                        className={'footer__policy'}
                        to={'/personal_data_policy'}
                        target={'_blank'}
                    >
                        Политика&nbsp;конфиденциальности
                    </Link>
                    <div >
                        Copyright © {currentYear} All rights reserved
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;