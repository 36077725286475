import React from 'react';

const RowCard = (props) => {
    return (
        <div className={props.rowClassName}>
            <div className={props.cardClassName}>{props.cardContent}</div>
            <div className={props.textClassName}>
                <p className="row-text">
                    {props.text}
                </p>

            </div>
        </div>

    );
};

export default RowCard;