import Button from "./button/button";
import React from "react";

export const ProductCard = (props) => {
    const {
        item,
        handleChoice
    } = props;

    const chooseProduct = () => {
        handleChoice(item);
    }

    return (
            <div className={"booking__item-content"} >
                <ul className="booking__item-list">
                    {item.description.split(';').map((el, index) =>
                        <li key={item.product_type + '-' + item.id + '-' + index}>
                            {el.toLowerCase()}
                        </li>
                    )}
                </ul>
                <p className={"booking__item-price"}>
                    Стоимость: {item.price} руб.
                </p>
                <Button
                    className="booking__btn btn"
                    text={"Хочу консультацию"}
                    onClick={chooseProduct}
                />
            </div>
        )
}