import React from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import About from "../pages/about";
import Reviews from "../pages/reviews";
import NotFound404 from "./notFound404";
import Booking from "../pages/booking";
import {PersonalDataPolicyPage} from "../pages/PersonalDataPolicyPage";

const AppRouter = () => {
    return (
        <Routes>
            <Route path="/" element={<About/>}/>
            <Route path="/booking" element={<Booking/>}/>
            <Route path="/reviews" element={<Reviews/>}/>
            <Route
                path="/personal_data_policy"
                element={<PersonalDataPolicyPage/>}
            />
            <Route path="/error" element={<NotFound404/>}/>
            <Route path="*" element={<Navigate to={"/error"}/>}/>
        </Routes>
    );
};

export default AppRouter;