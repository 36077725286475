 import { useEffect, useState } from "react";
 
 const useDeviceDetect = () => {
    const [deviceType, setDeviceType] = useState('');
  
    useEffect(() => {
      const handleResize = () => {
        const { innerWidth } = window;
        if (innerWidth <= 768) {
          setDeviceType('mobile');
        } else if (innerWidth > 768 && innerWidth <= 1024) {
          setDeviceType('tablet');
        } else {
          setDeviceType('desktop');
        }
      };
  
      window.addEventListener('resize', handleResize);
      handleResize();
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  
    return deviceType;
  };

  export default useDeviceDetect;