import React from 'react';
import Title from "../title";
import CardContent from "./CardContent";

const Card = (props) => {
    return (
        <div className={props.className}>
            <Title className={props.titleClassName}>
                {props.titleText}
            </Title>
            <CardContent className={props.contentClassName}>
                {props.content}
            </CardContent>
        </div>
    );
};

export default Card;