import React from 'react';

const TimeSlot = (props) => {

    const {
        className,
        onClick,
        children,
        ...otherProps
    } = props;

    const handleChange = (e) => {
        e.preventDefault();
        onClick(e.target.outerText);
    }

    return (
        <button
            className={className}
            onClick={e => handleChange(e)}
            {...otherProps}
        >
            {children}
        </button>
    );
};

export default TimeSlot;