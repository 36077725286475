import React, {useEffect, useState} from 'react';
import HelloTitle from "./HelloTitle";
import HelloBody from "./HelloBody";
import MainApp from "../API/mainApp";
import {greeting} from "../filling/aboutItemsFilling";

const Hello = () => {
    const [helloContent, setHelloContent] = useState({});

    async function fetchHelloContent() {
        const content = await MainApp.getHelloContent();
        setHelloContent(content);
    }

    useEffect(() => {
        fetchHelloContent()
    }, [])

    return (
        <section className="hello">
            <div className="hello__container _container">
                <HelloTitle text={helloContent.greeting || greeting}/>
                <HelloBody
                    text={helloContent.about}
                    img_src={helloContent.photo}
                />
            </div>
        </section>
    );
};

export default Hello;