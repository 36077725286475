import React from "react";

export let greeting = (
    <p> Привет! Я Любовь Прохорова,
        <span className="hello__title title-italic"> IT-фея
            </span> Консультирую
        <span className="hello__title title-italic"> IT и Digital специалистов
            </span> любого уровня <br/> по всему миру
    </p>
);

export let startState = {
    "how": {
        "id": null,
        "title": "",
        "content": [
            {
                "id": null,
                "cardTitle": "",
                "cardText": ""
            },
        ]
    },
    "help_query": {
        "id": null,
        "title": "",
        "content": [
            {
                "id": null,
                "cardTitle": "",
                "cardText": ""
            },
        ]
    },
    "accents": {
        "id": null,
        "title": "",
        "content": [
            {
                "id": null,
                "cardTitle": null,
                "cardText": ""
            },
        ]
    },
    "what": {
        "id": null,
        "title": "",
        "content": [
            {
                "id": null,
                "cardTitle": null,
                "cardText": ""
            }
        ]
    }
}

