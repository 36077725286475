const getEnvVar = (key) => {
    if (process.env[key] === undefined) {
        throw new Error(`Env variable ${key} is required.`)
    }
    return process.env[key];
};

// export const API_PORT = getEnvVar('REACT_APP_DJANGO_PORT');
export const API_PORT = '8000';
// export const API_HOST = getEnvVar('REACT_APP_HOST_IP') + ':' + API_PORT;
// export const API_HOST = `${HOST_NAME}:${API_PORT}`;
export const API_ORIGIN = `${window.location.origin}:${API_PORT}`;
// export const API_HOST = `0.0.0.0:${API_PORT}`;
