import React, { useState } from 'react';
import { Link } from "react-router-dom";
import useDeviceDetect from '../../../hooks/useDeviceDetect';
import CrossIcon from '../../../assets/img/cross.svg'


import MenuIcon from '../../../assets/img/menu.svg'

const HeaderMenu = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isMenuClosing, setMenuClosing] = useState(false);


    const device = useDeviceDetect();

    const onMenuClick = () => {
        setIsMenuOpen(true)
    }

    const onCloseMenuClick = () => {
        setMenuClosing(true);
        setTimeout(() => {
            setIsMenuOpen(false);
            setMenuClosing(false);
        }, 450);
    }




    if (isMenuOpen) {
        return (
            <div className={`header__menu-window ${isMenuOpen ? 'open' : ''} ${isMenuClosing ? 'closing' : ''}`}>
                <div className='header__menu-window__close-icon' onClick={onCloseMenuClick}>
                    <img src={CrossIcon} alt='close' />
                </div>
                {
                    !isMenuClosing && (
                        <ul className={`menu__list ${isMenuOpen ? 'open' : ''}`}>
                            <li>
                                <Link to={'/'} className="menu__link" onClick={onCloseMenuClick}>
                                    {"Главная"}
                                </Link>
                            </li>
                            <li>
                                <Link to={'/booking'} className="menu__link" onClick={onCloseMenuClick}>
                                    {"Забронировать"}
                                </Link>
                            </li>
                            <li>
                                <Link to={'/reviews'} className="menu__link" onClick={onCloseMenuClick}>
                                    {"Отзывы"}
                                </Link>
                            </li>
                        </ul>
                    )
                }

            </div>
        )
    }

    
    return (
        <div className="header__menu menu">
            {
                device === 'mobile'
                    ? <img src={MenuIcon} alt='menu' onClick={onMenuClick} />
                    : (
                        <nav className="menu__body">
                            <ul className="menu__list">
                                <li>
                                    <Link to={'/'} className="menu__link">
                                        {"Главная"}
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/booking'} className="menu__link">
                                        {"Забронировать"}
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/reviews'} className="menu__link">
                                        {"Отзывы"}
                                    </Link>
                                </li>

                            </ul>
                        </nav>
                    )
            }

        </div>
    );
};

export default HeaderMenu;