import React from 'react';
import Hello from "../components/hello";
import AboutContent from "../components/aboutContent";

const About = () => {
    return (
        <div className="page">
            <Hello/>
            <AboutContent/>
        </div>
    );
};

export default About;