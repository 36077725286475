import React, { useEffect, useState } from 'react';
import Card from "./UI/Card";
import ReviewsApp from "../API/reviewsApp";
import Button from "./UI/button/button";

const ReviewsContent = () => {
    const [reviewsItems, setReviewsItems] = useState([]);

    async function fetchReviews() {
        return await ReviewsApp.getReviews();
    }

    useEffect(() => {
        fetchReviews()
            .then(resp => {
                setReviewsItems(resp);
            })
            .catch(e => {
                console.log(e.message);
            });
    }, []);

    return (
        <div className="reviews__container _container">
            <Card
                className={"reviews__item"}
                titleClassName={"reviews__title small-title"}
                contentClassName={"reviews__content item-content"}
                titleText={(
                    <p>
                        Как консультации помогли решить карьерные вопросы и побороть выгорание
                    </p>
                )}
                content={reviewsItems.map((el, index) =>
                    <Card
                        // className={`reviews__card review-card-${index + 1}`}
                        className={(index + 1) % 2 === 0 ? "reviews__card review-card-even" : "reviews__card"}
                        titleClassName={"reviews__card-title micro-title"}
                        contentClassName={"reviews__card-text text"}
                        titleText={el.author}
                        content={el.content}
                        key={el.id}
                    />)}
            />
            <Button
                className={'insta__btn micro-title'}
                text={
                    <a
                        className={'insta__btn'}
                        href="https://instagram.com/belaymamba_it?igshid=YmMyMTA2M2Y="
                        target="_blank"
                    >
                        <p>Больше отзывов в Instagram</p>
                        <p className="_icon-arrow"></p>
                    </a>
                }
            />
        </div>

    );
};

export default ReviewsContent;