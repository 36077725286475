import React from 'react';

import CrossIcon from '../../../assets/img/cross.svg'

const Modal = ({ active, setActive, children }) => {
    const onClose = () => {
        setActive(false)
    }
    return (
        <div className={active ? "modal active" : "modal"}
            onClick={onClose}>
            <div className={active ? "modal__content active" : "modal__content"}
                onClick={e => e.stopPropagation()}>
                <div className='modal__close-icon' onClick={onClose} >
                    <img src={CrossIcon} alt='close' />
                </div>
                {children}
            </div>
        </div>
    );
};

export default Modal;