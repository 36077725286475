import React, {useEffect, useState} from 'react';
import RowCard from "./UI/RowCard";
import Card from "./UI/Card";
import ReviewsApp from "../API/reviewsApp";

const QuestionsContent = () => {
    const [items, setItems] = useState([]);

    const fetchQuestions = () => {
        ReviewsApp.getQuestions()
            .then((resp) => {
                setItems(resp);
            })
            .catch((error) => {
                console.log(error.message);
            })
    }

    useEffect(() => {
        fetchQuestions();
    }, []);

    return (
        <div className={"question__container _container"}>
            <Card
                className={"questions__item about-item"}
                titleClassName={"questions__title small-title"}
                contentClassName={"questions__item-content item-content"}
                titleText={
                    (<p>
                        Часто задаваемые вопросы
                    </p>)
                }
                content={items.map((item) =>
                    <RowCard
                        rowClassName={"about__row row-content"}
                        cardClassName={"about__row-card row-card"}
                        cardContent={item.question}
                        textClassName={"questions__row-text text"}
                        text={item.answer}
                        key={item.id}
                    />)}
            />
        </div>
    );
};

export default QuestionsContent;