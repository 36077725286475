import React from 'react';
import HeaderMenu from "./UI/HeaderMenu/HeaderMenu";
import HeaderLogo from "./UI/headerLogo/HeaderLogo";

const Header = (props) => {
    return (
        <header className="header">
            <div className="_container header__content ">
                <HeaderLogo className={"header__logo"}>
                    Любовь Прохорова
                </HeaderLogo>
                <HeaderMenu/>
            </div>
        </header>
    );
};

export default Header;