import React, {memo} from "react";
import {Link} from "react-router-dom";


export const AgreementComponent = memo((props) => {
    const {
        className,
        labelClassName,
        inputClassName,
        onChange,
        checked,
    } = props;

    return (
        <div className={className}>
            <label className={labelClassName}>
                Нажимая на кнопку, я соглашаюсь на обработку
                персональных данных <br/>на условиях &nbsp;
                <Link to={'/personal_data_policy'} target={'_blank'}>
                    Политики&nbsp;конфиденциальности
                </Link>
            </label>
            <input
                className={inputClassName}
                type={'checkbox'}
                onChange={onChange}
                checked={checked}
            />
        </div>
    )
})