import React from 'react';

const HelloTitle = (props) => {
    return (
        <h1 className="hello__title title">
            {props.text}
            {/*Привет! Я Любовь Прохорова,*/}
            {/*<span className="hello__title title-italic">IT-фея</span> Консультирую*/}
            {/*<span className="hello__title title-italic"> IT и Digital специалистов </span>*/}
            {/*любого уровня <br/> по всему миру*/}
        </h1>
    );
};

export default HelloTitle;