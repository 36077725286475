import React, { useEffect, useState } from 'react';
import { startState } from "../filling/aboutItemsFilling";
import Card from "./UI/Card";
import RowCard from "./UI/RowCard";
import AboutApp from "../API/aboutApp";
import { Loader } from './UI/Loader/Loader';

const AboutContent = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [item1, setItem1] = useState(startState.what);
    const [item2, setItem2] = useState(startState.accents);
    const [item3, setItem3] = useState(startState.help_query);
    const [item4, setItem4] = useState(startState.how);

    useEffect(() => {
        setIsLoading(true);

        async function fetchAboutItems() {
            try {
                const resp = await AboutApp.getAboutContent();
                setItem1(resp.what);
                setItem2(resp.accents);
                setItem3(resp.help_query);
                setItem4(resp.how);
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        }

        fetchAboutItems();

    }, []);

    if (isLoading) {
        return <div className='loader'>
            <Loader />
        </div>
    }
    return (
        <section className="about">
            <div className="about__container _container">

                <Card
                    className={"about__item-1 about-item"}
                    titleClassName={"about__title small-title title-italic"}
                    contentClassName={"about__item-content-1 item-content"}
                    titleText={item1.title}
                    content={
                        <div className="about__text text">
                            {item1.content[0].cardText}
                        </div>
                    }
                />

                <Card
                    className={"about__item-2 about-item"}
                    titleClassName={"about__title small-title title-italic"}
                    contentClassName={"about__item-content-2 item-content"}
                    titleText={item2.title}
                    content={item2.content.map((content) =>
                        <Card
                            contentClassName={"about__card-2 card"}
                            content={content.cardText}
                            key={item2.id + '-' + content.id}
                        />)}
                />

                <Card
                    className={"about__item-3 about-item"}
                    titleClassName={"about__title small-title title-italic"}
                    contentClassName={'about__item-content-3 item-content'}
                    titleText={item3.title}
                    content={item3.content.map((rowContent) =>
                        <RowCard
                            rowClassName={"about__row row-content"}
                            cardClassName={"about__row-card row-card"}
                            cardContent={rowContent.cardTitle}
                            textClassName={"about__row-text text"}
                            text={rowContent.cardText}
                            key={item3.id + '-' + rowContent.id}
                        />)}
                />

                <Card
                    className={"about__item-4 about-item"}
                    titleClassName={"about__title small-title title-italic"}
                    contentClassName={'about__item-content-4 item-content'}
                    titleText={item4.title}
                    content={item4.content.map((content, index) =>
                        <Card
                            className={"about__card-4 card"}
                            titleClassName={"about__card-title micro-title title-italic"}
                            contentClassName={"about__card-4-text text"}
                            titleText={content.cardTitle}
                            content={content.cardText}
                            key={item4.id + '-' + index}
                        />)}
                />
            </div>
        </section>
    );
};

export default AboutContent;