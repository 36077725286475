import React from 'react';

const NotFound404 = (props) => {
    return (
        <div className={"error__container _container"}>
            <h1 className={"error-text title"}>Страница не найдена</h1>
        </div>
    );
};

export default NotFound404;