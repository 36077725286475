import React from 'react';
import BookingContent from "../components/bookingContent";

const Booking = () => {
    return (
        <div className="page">
            <BookingContent/>
        </div>
    );
};

export default Booking;