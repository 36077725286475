import React, {memo} from "react";
import {DocumentParagraph} from "./DocumentParagraph";
import {Loader} from "./UI/Loader/Loader";


export const PersonalDataPolicyContent = memo(({policy, isLoading}) => {
    return (
        <div className={'personalDataPolicy__container _container'}>
            {
                isLoading
                    ? <Loader/>
                    : (
                        <>
                            <p className={'personalDataPolicy__title small-title'}>
                                {policy.title}
                            </p>
                            {policy.paragraphs && policy.paragraphs.map(paragraph => (
                                <DocumentParagraph
                                    key={paragraph.id}
                                    paragraph={paragraph}
                                />
                            ))}
                        </>
                    )
            }

        </div>
    )
})