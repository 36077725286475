import axios from "axios";
import {API_ORIGIN} from "./config";


// TODO: вынести все url в конфиг
export default class MainApp {
    static async getHelloContent() {
        const response = await axios.get(`${API_ORIGIN}/api/v1/about/`);
        return response.data;
    }

    static async getPersonalDataPolicy() {
        const response = await axios.get(`${API_ORIGIN}/api/v1/legal_documents/1/`);
        return response.data;
    }

}