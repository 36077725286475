import React from "react";
import {BrowserRouter} from "react-router-dom";
import './styles/App.css'
import Footer from "./components/footer";
import Header from "./components/Header";
import AppRouter from "./components/appRouter";


function App() {
    return (
        <BrowserRouter>
            <div className="App">
                <Header/>
                <AppRouter/>
                <Footer/>
            </div>
        </BrowserRouter>
    );
}

export default App;
