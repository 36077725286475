import Button from "../button/button";
import React from "react";

export const ModalMessage = (props) => {
    const {callback, children} = props;

    return (
            <div className="booking__form">
                <p className={'booking__item-title micro-title'}>
                    {children}
                </p>
                <Button
                    type={'submit'}
                    className="booking__btn btn"
                    onClick={callback}
                    text={'OK'}
                />
            </div>
        )
}