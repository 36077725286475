import React, {useEffect, useState} from 'react';
import MainApp from "../API/mainApp";
import {
    PersonalDataPolicyContent
} from "../components/PersonalDataPolicyContent";

export const PersonalDataPolicyPage = () => {
    const [policy, setPolicy] = useState({})
    const [isLoading, setIsLoading] = useState(false);

    const fetchPersonalDataPolicy = async () => {
        setIsLoading(true);
        await MainApp.getPersonalDataPolicy()
            .then(resp => {
                setPolicy(resp);
                setIsLoading(false);
            })
            .catch(e => {
                console.log(e.message);
            });
    };

    useEffect(() => {
        fetchPersonalDataPolicy();
    }, []);

    return (
        <div className="page">
            <PersonalDataPolicyContent
                policy={policy}
                isLoading={isLoading}
            />
        </div>
    );
};

