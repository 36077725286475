import React, {memo, useEffect, useMemo, useState} from "react";


export const DocumentParagraphItem = memo(({paragraph, item}) => {
    const [number, setNumber] = useState('');
    const [title, setTitle] = useState('');
    const [subList, setSubList] = useState([]);

    useEffect(() => {
        if (item.number) {
            setNumber(`${paragraph.number}.${item.number}.`);
        }

        if (item.title) {
            setTitle(item.title);
        }

        if (item.sub_list) {
            const itemSubList = item.sub_list.split(';');
            setSubList(itemSubList);
        }
    }, []);

    return (
        <div className={'personalDataPolicy__paragraph__content'}>
            <p className={'personalDataPolicy__paragraph__content__item text'}>
                {`${number} ${title} ${item.content}`}

            </p>
            {
                subList.length
                    ? (
                        <ul>
                            {subList.map(el => (
                                <li
                                    key={el}
                                    className={'text'}
                                >
                                    {el}
                                </li>
                            ))}
                        </ul>
                    )
                    : null
            }
        </div>
    )
})