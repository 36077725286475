import React from 'react';
import Avatar from "./UI/avatar/Avatar";
import Button from "./UI/button/button";
import { useNavigate } from "react-router-dom";
import useDeviceDetect from '../hooks/useDeviceDetect';

const HelloBody = (props) => {
    const navigate = useNavigate();
    const device = useDeviceDetect();



    const handleClick = () => {
        navigate('/booking');
    };

    if (device === 'mobile') {
        return (
            <div className="hello__body body">
                <div className="hello__content">
                    <div className="hello__text text">
                        {props.text}
                    </div>

                </div>
                <Avatar
                    className={"hello__avatar"}
                    src={"./images/img1.jpg"}
                />
                <Button
                    className="hello__btn btn"
                    text={"Хочу консультацию"}
                    onClick={handleClick}
                />
            </div>
        )
    }
    return (
        <div className="hello__body body">
            <div className="hello__content">
                <div className="hello__text text">
                    {props.text}
                </div>
                <Button
                    className="hello__btn btn"
                    text={"Хочу консультацию"}
                    onClick={handleClick}
                />
            </div>
            <Avatar
                className={"hello__avatar"}
                src={"./images/img1.jpg"}
            />
        </div>
    );


};

export default HelloBody;