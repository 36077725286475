import axios from "axios";
import {API_ORIGIN} from "./config";

export default class ReviewsApp {
    static async getReviews() {
        const response = await axios.get(`${API_ORIGIN}/api/v1/reviews/`);
        return response.data;
    }

    static async getQuestions() {
        const response = await axios.get(`${API_ORIGIN}/api/v1/questions/`);
        return response.data;
    }
}