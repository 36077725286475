import React, {useState} from 'react';

import ReviewsContent from "../components/reviewsContent";
import QuestionsContent from "../components/questionsContent";

const Reviews = () => {
    return (
        <section className="reviews">
            <ReviewsContent/>
            <QuestionsContent/>
        </section>
    );
};

export default Reviews;